// ** CAROUSEL FOR TESTIMONIALS
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import {
  siteWidth,
  darkBlue,
  lightBlue,
  mainWhite,
  StyledLink,
  Subtitle,
  screen,
} from "../common/variables";
import { reduceWords } from "../../helpers";
import { FaChevronRight, FaChevronLeft, FaLess } from "react-icons/fa";
import bgImg from "../../images/insights-bg.png";
import quote from "../../images/quote.svg";

// Service prop for different styling for program page.
const Wrapper = styled.section`
  max-width: ${siteWidth};
  margin: 0 auto;
  background-image: ${(props) => (props.service ? `url(${bgImg})` : "none")};
  background-color: ${(props) => (props.service ? `#EDECEC` : darkBlue)};

  .slider-container-large {
    display: none;
    @media ${screen.medium} {
      display: block;
      height: 630px;
    }
    @media ${screen.medium} {
      height: auto;
    }
    .slider-card-container {
      padding: ${(props) =>
        props.service ? `180px 70px 120px 70px` : `120px 70px 120px 70px`};
      outline: none;
      display: flex;
      margin: 0;
    }

    .slider-card {
      background-color: ${mainWhite};
      position: relative;
      border-radius: 10px;
      padding: 45px 90px;
      box-sizing: content-box;
      color: ${darkBlue};
      transition: all 0.1s ease-out;
      transform: translateY(54px);

      &__img {
        width: 160px;
        height: 160px;
        position: absolute;
        top: -30px;
        right: -17px;
        img {
          border-radius: 50%;
          width: 100%;
        }
      }

      &__msg {
        font-size: 1rem;
        max-width: 460px;
        position: relative;
        font-weight: 300;
        padding-top: 10px;
        img {
          width: 40px;
          position: absolute;
          top: -10px;
          left: -55px;
        }
      }

      &__name {
        font-size: 0.9rem;
        font-weight: 600;
        margin-top: 18px;
      }

      &__company {
        text-transform: uppercase;
        margin-top: 3px;
        font-weight: 300;
        font-size: 0.9rem;
      }

      a {
        margin-top: 20px;
        font-size: 0.9rem;
        padding: 14px 25px;
        background: ${(props) => (props.service ? darkBlue : lightBlue)};
        &:hover {
          background: ${(props) => (props.service ? lightBlue : darkBlue)};
        }
      }
    }

    .slick-current,
    .slick-center {
      .slider-card {
        transform: translateY(0);

        &__img {
          width: 185px;
          height: 185px;
          top: -40px;
          right: -32px;
        }
        &__msg {
          font-size: 1.4rem !important;
          font-weight: 300;
          padding-top: 10px;
          img {
            width: 45px;
            top: -13px;
            left: -65px;
          }
        }
        &__name {
          font-size: 1rem;
        }
        &__company {
          font-size: 1rem;
        }

        a {
          margin-top: 20px;
          font-size: 1.05rem;
          padding: 16px 30px;
        }
      }
    }

    .slick-dots {
      bottom: 0;
      padding-bottom: 20px;

      li {
        margin: 0 7px;

        button {
          border: ${(props) =>
            props.service
              ? `2px solid ${darkBlue}`
              : `2px solid ${mainWhite}`} !important;
          border-radius: 50%;
          width: 15px;
          height: 15px;

          &::before {
            border-radius: 50%;
            opacity: 1 !important;
            color: ${(props) =>
              props.service ? `#EDECEC` : darkBlue} !important;
            width: 15px;
            height: 15px;
          }
        }
      }

      .slick-active {
        button {
          background-color: ${mainWhite} !important;

          &::before {
            background-color: ${(props) =>
              props.service ? darkBlue : mainWhite} !important;

            color: ${(props) =>
              props.service ? darkBlue : mainWhite} !important;
          }
        }
      }
    }

    &__success-title {
      display: none;
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      padding-top: 60px;
      @media ${screen.medium} {
        display: ${(props) => (props.service ? `block` : `none`)};
      }
    }
  }

  /*  START SMALL SLIDER */

  .slider-container-small {
    position: relative;
    @media ${screen.medium} {
      display: none;
    }

    &__success-title {
      display: ${(props) => (props.service ? `block` : `none`)};
      text-align: center;
      position: absolute;
      left: 50%;
      top: 30px;
      transform: translateX(-50%);
      @media ${screen.xSmall} {
        top: 28px;
      }
      @media ${screen.medium} {
        display: none;
      }
    }

    &__next-btn {
      color: ${(props) => (props.service ? darkBlue : mainWhite)};
      font-size: 1.9rem;
      cursor: pointer;
      position: absolute;
      right: 6px;
      top: ${(props) => (props.service ? "310px" : "240px")};
      transform: translateY(-50%);
      z-index: 9;
      @media ${screen.xSmall} {
        font-size: 2.7rem;
        right: 28px;
      }
    }

    &__prev-btn {
      color: ${(props) => (props.service ? darkBlue : mainWhite)};
      font-size: 1.9rem;
      cursor: pointer;
      position: absolute;
      left: 6px;
      top: ${(props) => (props.service ? "310px" : "240px")};
      transform: translateY(-50%);
      z-index: 9;
      @media ${screen.xSmall} {
        font-size: 2.7rem;
        left: 28px;
      }
    }
    .slider-card-container {
      padding: ${(props) =>
        props.service ? `115px 45px 90px 45px` : `45px 45px 90px 45px`};
      margin: 0 50px;
      outline: none;
      display: flex;
      margin: 0;
      @media ${screen.xSmall} {
        padding: ${(props) =>
          props.service ? `115px 45px 100px 45px` : `45px 45px 100px 45px`};
      }
    }

    .slider-card {
      background-color: ${mainWhite};
      position: relative;
      border-radius: 10px;
      padding: 80px 25px 25px 25px;
      box-sizing: content-box;
      color: ${darkBlue};
      transition: all 0.1s ease-out;
      transform: translateY(54px);
      max-width: 430px;
      margin: 0 auto;
      @media ${screen.xSmall} {
        padding: 90px 50px 25px 50px;
      }

      &__img {
        width: 150px;
        height: 150px;
        position: absolute;
        top: -75px;
        left: 50%;
        transform: translate(-50%);
        @media ${screen.xSmall} {
          width: 160px;
          height: 160px;
        }
        img {
          border-radius: 50%;
          width: 100%;
        }
      }

      &__msg {
        font-size: 1.1rem;
        position: relative;
        font-weight: 300;
        text-indent: 35px;
        margin-top: 5px;

        img {
          width: 30px;
          position: absolute;
          top: -8px;
          left: -5px;
          @media ${screen.xSmall} {
            width: 35px;
            top: -10px;
            left: -12px;
          }
        }
      }

      &__name {
        font-size: 0.9rem;
        font-weight: 600;
        margin-top: 5px;
      }

      &__company {
        text-transform: uppercase;
        margin-top: 3px;
        margin-bottom: 15px;
        font-weight: 300;
        font-size: 0.7rem;
      }

      a {
        background: ${(props) => (props.service ? darkBlue : lightBlue)};
        &:hover {
          background: ${(props) => (props.service ? lightBlue : darkBlue)};
        }
        width: 100%;
        text-align: center;
        @media ${screen.small} {
          width: auto;
          margin: 0 auto;
        }
        @media ${screen.medium} {
          margin: 0;
        }
      }
    }
  }

  /*  END SMALL SLIDER */
`;

// Carousel settings for large/desktop screen.
const largeSettings = {
  accessibility: false,
  autoplay: true,
  dots: true,
  speed: 700,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
  variableWidth: true,
  infinite: true,
  focusOnSelect: true,
  pauseOnHover: false,
  swipe: false,
  autoplaySpeed: 3300,
  adaptiveHeight: true,
};
// Carousel settings for small/mobile screen.
const smallSettings = {
  accessibility: false,
  autoplay: true,
  dots: false,
  speed: 700,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  infinite: true,
  focusOnSelect: true,
  pauseOnHover: false,
  swipe: false,
  adaptiveHeight: true,
  autoplaySpeed: 3700,
};

const Carousel = (props) => {
  // Store next/prev controller from react-slick. it's for fully custom arrow style/positioning.
  const [navigateControl, setNavigateControl] = useState(null);

  return (
    <Wrapper service={props.service}>
      <Subtitle className="slider-container-large__success-title" dark>
        Success stories
      </Subtitle>
      <Slider className="slider-container-large" {...largeSettings}>
        {props.testimonials.map((testimonial) => {
          return (
            <div
              style={{ width: 850 }}
              key={testimonial.node.id}
              className="slider-card-container"
            >
              <div className="slider-card">
                <div className="slider-card__img">
                  <img
                    src={testimonial.node.data.photo.url}
                    alt={testimonial.node.data.full_name.text}
                  />
                </div>
                <p className="slider-card__msg">
                  {reduceWords(testimonial.node.data.message.text)}
                  <img src={quote} alt="quote" />
                </p>
                <h3 className="slider-card__name">
                  {testimonial.node.data.full_name.text}
                </h3>
                <p className="slider-card__company">
                  {testimonial.node.data.occupation_title.text}{" "}
                  {testimonial.node.data.occupation_title.text && " - "}
                  {testimonial.node.data.company.text}
                </p>
                <StyledLink
                  to="/success-stories"
                  state={{ selectedId: testimonial.node.id }}
                >
                  READ MORE <FaChevronRight />
                </StyledLink>
              </div>
            </div>
          );
        })}
      </Slider>
      <div className="slider-container-small">
        <p
          className="slider-container-small__next-btn"
          onClick={navigateControl ? navigateControl.slickNext : null}
        >
          <FaChevronRight />
        </p>
        <p
          className="slider-container-small__prev-btn"
          onClick={navigateControl ? navigateControl.slickPrev : null}
        >
          <FaChevronLeft />
        </p>
        <Subtitle className="slider-container-small__success-title" dark>
          Success stories
        </Subtitle>
        <Slider ref={(c) => setNavigateControl(c)} {...smallSettings}>
          {props.testimonials.map((testimonial) => {
            return (
              <div key={testimonial.node.id} className="slider-card-container">
                <div className="slider-card">
                  <div className="slider-card__img">
                    <img
                      src={testimonial.node.data.photo.url}
                      alt={testimonial.node.data.full_name.text}
                    />
                  </div>
                  <p className="slider-card__msg">
                    {reduceWords(testimonial.node.data.message.text)}
                    <img src={quote} alt="quote" />
                  </p>
                  <h3 className="slider-card__name">
                    {testimonial.node.data.full_name.text}
                  </h3>
                  <p className="slider-card__company">
                    {testimonial.node.data.occupation_title.text}
                    {testimonial.node.data.occupation_title.text && " - "}
                    {testimonial.node.data.company.text}
                  </p>
                  <StyledLink
                    to="/success-stories"
                    state={{ selectedId: testimonial.node.id }}
                  >
                    READ MORE <FaChevronRight />
                  </StyledLink>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </Wrapper>
  );
};

export default Carousel;
